@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Source+Code+Pro:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Sora:wght@100;200;300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Karla:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

@layer base {
  body {
    @apply bg-neutral-8 text-white;
  }

  h1 {
    @apply text-2xl sm:text-[30px] md:text-[50px] xl:text-[60px] leading-normal;
  }

  h2 {
    @apply text-2xl sm:text-[28px] md:text-[40px] xl:text-[48px] leading-normal;
  }

  h4 {
    @apply text-[20px] md:text-[24px] xl:text-[32px] leading-normal;
  }

  h5 {
    @apply text-[20px] md:text-[24px] leading-normal;
  }

  h6 {
    font-size: 18px;
    font-weight: 600;
    @apply leading-normal;
  }
}

.splide__pagination {
  display: flex;
  gap: 16px;
}

.splide__pagination__page {
  background-color: #fff !important;
  margin: 10px !important;
  position: relative;
}

.splide__pagination__page::after {
  content: url("./assets/vectors/gradient-ring.svg");
  position: absolute;
  left: 50%;
  top: calc(50% + 1px);
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: 0.4s ease;
}

.splide__pagination__page.is-active {
  transform: none !important;
}

.splide__pagination__page.is-active::after {
  opacity: 1;
}

.splide {
  padding-bottom: 100px;
}
