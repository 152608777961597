.serviceCard {
  background: linear-gradient(
      180deg,
      rgba(86, 86, 86, 0.85) 0%,
      rgba(194, 194, 194, 0) 56.28%
    ),
    linear-gradient(0deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0.15));
  padding: 2px;
  border-radius: 40px 80px 40px 40px;

  transition: all 0.4s ease;
}

.serviceCard:hover {
  background: linear-gradient(
      180deg,
      rgba(255, 200, 118, 0.85) 0%,
      rgba(255, 51, 51, 0) 56.28%
    ),
    linear-gradient(0deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0.15));
}

.serviceCard > div:first-child {
  border-radius: 40px 80px 40px 40px;
}

@media screen and (max-width: 640px) {
  .serviceCard {
    border-radius: 20px 40px 20px 20px;
  }

  .serviceCard > div:first-child {
    border-radius: 20px 40px 20px 20px;
  }
}
